import React from 'react'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useState } from 'react';
import { useEffect } from 'react';
import Slider from 'react-slick';
import {  multiData } from './data';
let slidesToShow = 5;
const PreviousBtn = (props) => {
    console.log(props);
    const { className, onClick, currentSlide } = props;
    return (
      <>
        {currentSlide !== 0 && (
          <div className={className} onClick={onClick}>
            <KeyboardArrowLeftIcon style={{ color: 'blue', fontSize: '30px' }} />
          </div>
        )}
      </>
    );
  };
  const NextBtn = (props) => {
    const { className, onClick, slideCount, currentSlide } = props;
    console.log(props);
    return (
      <>
        {currentSlide !== slideCount - slidesToShow && (
          <div className={className} onClick={onClick}>
            <KeyboardArrowRightIcon style={{ color: 'blue', fontSize: '30px' }} />
          </div>
        )}
      </>
    );
  };
  const carouselProperties = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    slidesToShow: slidesToShow,
    slidesToScroll: 2,
    infinite: false,
    autoplay:true,
    // slidesToScroll={3}
    responsive: [
      {
        breakpoint: 426,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          centerMode: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          centerMode: false,
          slidesToScroll: 2,
        },
      },
    ],
  };
const NewSs = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const updateWidth = () => {
      setWidth(window.innerWidth);
    };
  
    useEffect(() => {
      window.addEventListener('resize', updateWidth);
      return () => window.removeEventListener('resize', updateWidth);
    }, []);
  
    if (width <= 426) {
      slidesToShow = 1;
    } else if (width > 426 && width <= 769) {
      slidesToShow = 3;
    } else if (width > 769 && width <= 1025) {
      slidesToShow = 4;
    } else {
      slidesToShow = 5;
    }
  
    return (
      <div style={{ margin: '50px' }} className='carousel'>
        <h2 className='text-center'>Latests News</h2>
        <Slider {...carouselProperties}>
          {multiData.map((item) => (
            <Card key={item.id} item={item} />
          ))}
        </Slider>
      </div>
    );
  };
  
  const Card = ({ item }) => {
    return (
      <div style={{ textAlign: 'center',boxShadow: ' 10px 10px 51px -30px rgba(0,0,0,0.75)' }} className='mx-3'>
        <img
          className='multi__image'
          src={item.imageUrl}
        alt={item.title}
          style={{
            width: '100%',
            height: '170px',
            objectFit: 'contain',
            marginBottom: '10px',
          }}
        />
        <p style={{ fontSize: '14px', padding: '5px 0' }}>{item.title}</p>
        <p>
        <p>{item.description}</p>
        </p>
          <button className='btn main-btn' style={{}}>Know More</button>
      </div>
    );
  };

export default NewSs