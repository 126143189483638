import multicar1 from "../img/slide1.webp";
import multicar2 from "../img/slide1.webp";
import multicar3 from "../img/slide1.webp";
import multicar4 from "../img/slide1.webp";
import multicar5 from "../img/slide1.webp";
import multicar6 from "../img/slide1.webp";
import multicar7 from "../img/slide1.webp";
import multicar8 from "../img/slide1.webp";
import multicar9 from "../img/slide1.webp";
import multicar10 from "../img/slide1.webp";
import multicar11 from "../img/slide1.webp";
export const multiData = [
  {
    id: 1,
    imageUrl: 'https://t3.ftcdn.net/jpg/05/95/97/78/240_F_595977874_A0R6YeTMz1ruNxBackdwXRYYe8XWiNGy.jpg',
    title: 'Breaking News 1',
    description: 'This is the description for the first breaking news.',
    link: 'https://example.com/news1'
  },
  {
    id: 2,
    imageUrl: 'https://t3.ftcdn.net/jpg/08/35/21/88/240_F_835218802_OrD1AGIsL0i1wYndUJOp3jspBAfj4h33.jpg',
    title: 'Breaking News 2',
    description: 'This is the description for the second breaking news.',
    link: 'https://example.com/news2'
  },
  {
    id: 3,
    imageUrl: 'https://as2.ftcdn.net/v2/jpg/02/67/63/27/1000_F_267632714_lVq7kUVqtGvdjFBDSseEDNCiTO6ACHSn.jpg',
    title: 'Breaking News 3',
    description: 'This is the description for the third breaking news.',
    link: 'https://example.com/news3'
  },
  {
    id: 4,
    imageUrl: 'https://as2.ftcdn.net/v2/jpg/04/22/24/49/1000_F_422244902_ulvbCnVfyp7Wo0qbX4cntEIaOR4n8VVt.jpg',
    title: 'Breaking News 4',
    description: 'This is the description for the fourth breaking news.',
    link: 'https://example.com/news4'
  },
  {
    id: 5,
    imageUrl: 'https://t3.ftcdn.net/jpg/07/51/87/80/240_F_751878000_wuFXZDGS7mknbukktd3gAPLtumBp4g1B.jpg',
    title: 'Breaking News 5',
    description: 'This is the description for the fifth breaking news.',
    link: 'https://example.com/news5'
  }
];