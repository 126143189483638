import React from 'react';
 
const RecognisedBy = () => {
  const items = [
    {
      img: "https://indiraicp.edu.in/assets/images/demoImg/icon5.png",
      alt: "Autonomous College Affiliated to SPPU",
      text: "AUTONOMOUS COLLEGE AFFILIATED TO SPPU."
    },
    {
      img: "https://indiraicp.edu.in/assets/images/demoImg/icon1.png",
      alt: "Grade A+ NAAC Accreditation",
      text: "GRADE A+ NAAC ACCREDITATION"
    },
    {
      img: "https://indiraicp.edu.in/assets/images/demoImg/icon2.png",
      alt: "PCI & AICTE Approved College",
      text: "PCI & AICTE APPROVED COLLEGE"
    },
    {
      img: "https://indiraicp.edu.in/assets/images/demoImg/icon3.png",
      alt: "Platinum Rank by AICTE-CII Survey",
      text: "PLATINUM RANK BY AICTE-CII SURVEY"
    },
    {
      img: "https://indiraicp.edu.in/assets/images/demoImg/icon4.png",
      alt: "Best Institute in Pharmacy",
      text: "“BEST INSTITUTE IN PHARMACY” AT THE 9TH"
    },
    {
      img: "https://indiraicp.edu.in/assets/images/demoImg/icon6.png",
      alt: "Study in Vibrant City like Pune",
      text: "STUDY IN VIBRANT CITY LIKE PUNE"
    },
    {
      img: "https://indiraicp.edu.in/assets/images/demoImg/icon7.png",
      alt: "Outcome Based Education",
      text: "OUTCOME BASED EDUCATION"
    },
    {
      img: "https://indiraicp.edu.in/assets/images/demoImg/icon8.png",
      alt: "Highest Employability Record",
      text: "HIGHEST EMPLOYABILITY RECORD"
    }
  ];
 
  return (
    <div className="container my-5">
      <div className="row mb-4">
        <div className="col-12 text-center">
          <h2 className="">Recognised by</h2>
        </div>
      </div>
      <div className="row">
        {items.map((item, index) => (
          <div className="col-md-4 col-sm-6 mb-4 d-flex justify-content-between align-items-center" key={index} >
            {/* <div className=""> */}
              <div className="card-body d-flex flex-cloumn justify-content-center align-items-center" style={{ boxShadow: '0 1px 5px rgba(239, 68, 68, 0.5)', borderRadius: '10px' }}>
                <img src={item.img} className="" alt={item.alt} style={{maxWidth: '25%', height: 'auto',}}/>
                <p className="mx-2" style={{fontSize:"14px"}}>{item.text}</p>
              </div>
            {/* </div> */}
          </div>
        ))}
      </div>
    </div>
  );
}
 
export default RecognisedBy;