import React from 'react'
import '../style/Banner.css'
import gurukul2 from '../img/banner 2.jpg'
import gurukul3 from '../img/gurukul3.jpeg'
import Banner2 from '../img/banner 3.jpg'
 
 
const Banner = () => {
  return (
    <div className='container-fluid banner'>
    <div className='row'>
      <div className="col-12 m-0 p-0">
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="d-block w-100" src={gurukul3} alt="First slide" />
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={gurukul2} alt="Second slide" />
            </div>
            <div className="carousel-item">
              <img className="d-block w-100" src={Banner2} alt="Third slide" />
            </div>
          </div>
          <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  )
}
 
export default Banner