
import './App.css';
import Banner from './component/Banner';

import Footer from './component/Footer';
import GNavbar from './component/GNavbar';
import Management from './component/Management';
import NewSs from './component/NewSs';
import News from './component/News';
import Placement from './component/Placement';
import RecognisedBy from './component/RecognisedBy';
import Welcome from './component/Welcome';
import WhyChooseUs from './component/WhyChooseUs';

function App() {
  return (
    <div className="App">
    <GNavbar />
    <Banner />
    <Welcome />
    <WhyChooseUs />
    <RecognisedBy />
    <NewSs />
    <Placement />
   <Management />
    <Footer />
    </div>
  );
}

export default App;
