import React from 'react';
 
const WhyChooseUs = () => {
    return (
        <div className="container mt-5 heading-border mb-5">
            <h2 className=" text-center">Why Choose Us?</h2>
            <div className="row mt-3 p-3 card d-flex flex-md-row flex-column justify-content-start align-items-start" style={{ boxShadow: '0 1px 5px rgba(239, 68, 68, 0.5)', borderRadius: '10px' }}>
               
                    <div className="col-12 col-md-6 px-3">
                        <ul style={{listStyle:'none',}}>
                            <li className="mt-3"><i className="fas fa-chalkboard-teacher" style={{color:'var(--secondary-color)',}}></i> Well qualified, dedicated, research-oriented faculty and staff.</li>
                            <li className="mt-3"><i className="fas fa-flask" style={{color:'var(--secondary-color)',}}></i> State of art laboratories with all modern and advanced equipments.</li>
                            <li className="mt-3"><i className="fas fa-lightbulb" style={{color:'var(--secondary-color)',}}></i> R&amp;D Cell.</li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 px-3">
                        <ul style={{listStyle:'none'}}>
                            <li className="mt-3"><i className="fas fa-briefcase" style={{color:'var(--secondary-color)',}}></i> Efficient Placement cell.</li>
                            <li className="mt-3"><i className="fas fa-theater-masks" style={{color:'var(--secondary-color)',}}></i> Open air amphitheater</li>
                            <li className="mt-3"><i className="fas fa-basketball-ball" style={{color:'var(--secondary-color)',}}></i> Sports center</li>
                        </ul>
                    </div>
              
            </div>
        </div>
    )
}
 
export default WhyChooseUs;