import React from 'react';
import Slider from 'react-slick';
import cipla from '../img/cipla.png';
import alembic from '../img/alembic.png';
import cognizant from '../img/cognizant.png';
import gsk from '../img/gsk.png';
import mylan from '../img/mylan.png';
import tcs from '../img/tcs.png';
import maxheal from '../img/maxheal.png';
import macleods from '../img/macleods.png';
 
const Placement = () => {
 
  const placements = [
    { image: cipla },
    { image: alembic },
    { image: cognizant },
    { image: gsk },
    { image: mylan },
    { image: tcs },
    { image: maxheal },
    { image: macleods }
  ];
 
  const settings = {
    infinite: true,
    speed: 7000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3 }
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 2 }
      },
      {
        breakpoint: 480,
        settings: { slidesToShow: 1 }
      }
    ]
  };
 
  return (
    <div className='mt-5'>
      <div className='container-fluid mt-5'>
        <div className='row heading-border'>
          <h2 className='text-center mt-4' >
            Placement
          </h2>
        </div>
 
        <div className='row text-center mt-4'>
          <Slider {...settings}>
            {placements.map((placements, index) => (
              <div key={index} className='col-6 col-md-3'>
                <img src={placements.image} style={{ width: '120px', height: '110px' }}  />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
 
export default Placement;