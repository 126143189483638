import React from 'react'
import '../style/Gnavbar.css'
import Logo from '../img/GURUKUL LOGO.png'
const GNavbar = () => {
  return (
    <header className="header_wrapper">
    <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="#">
              <img decoding="async" src={Logo} className="img-fluid" style={{width:'300px'}} />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            {/* <!-- <span className="navbar-toggler-icon"></span> --> */}
            <i className="fas fa-stream navbar-toggler-icon"></i>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav  menu-navbar-nav">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#home">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">About-us</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#services">Courses</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#team">Admission</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#gallery">Research</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#testimonial">Placement</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
</header>

  )
}

export default GNavbar