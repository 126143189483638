import React from 'react'
import '../style/Management.css'
import Head from '../img/head.jpg'
import President from '../img/president.jpg'

const Management = () => {
    return (
        <div class="wrapper pb-0">
        <div class="container text-center">
            <h2>Management</h2>
            <div class="row mt-3">
                <div class="col-md-6 col-sm-6 mb-4">
                    <div class="card text-center">
                        <div class="icon-box">
                            <img decoding="async" src={President} style={{width:'220px',height:'220px',objectFit:'contain'}} />
                        </div>
                        <div>
                            <h4>President 
                            </h4>
                            <p>Laxmanrao Khanduji dalvi</p>

                        </div>
                    </div>
                </div>
                
                <div class="col-md-6 col-sm-6 mb-4">
                    <div class="card text-center">
                        <div class="icon-box">
                            <img decoding="async" src={Head} style={{width:'220px',height:'220px',objectFit:'contain'}} />
                        </div>
                        <div>
                            <h4>Secretary</h4>
                            <p>Yogesh laxmanrao dalvi</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Management