import React from 'react'
import '../style/Footer.css'
const Footer = () => {
  return (
    // <!-- Footer section --> 
    <section id="contact" className="footer_wrapper wrapper">
        <div className="container pb-3">
            <div className="row">
                <div className="col-lg-3 col-md-6 mb-4">
                    <h5>College Location</h5>
                    <p className="ps-0">Gurukul Pharmacy College is an institution dedicated to providing education and training in the field of pharmacy. It typically offers undergraduate and postgraduate programs in pharmaceutical sciences. </p>
                    <div className="contact-info">
                        <ul className="list-unstyled p-0">
                            <li><a href="#"><i className="fa fa-home me-3"></i> Bhokardan- 431114</a></li>
                            <li><a href="#"><i className="fa fa-phone me-3"></i>+919922335250</a></li>
                            <li><a href="#"><i className="fa fa-envelope me-3"></i>dnyanyogspm@gmail.com.com</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-4">
                    <h5>Contact</h5>
                    <ul className="link-widget p-0" style={{listStyle:'none'}}  >
                        <li style={{listStyle:'none'}}><a href="">Admission desk number,</a> 
                        </li>
                        <a href="#" style={{color:'white',fontSize:'.875rem',textDecoration:'none'}}><i className="fa fa-phone me-3"></i> 9975757597</a>
                        <li style={{marginTop:'10px'}}><a href="#">College help desk number</a></li>
                       <a href="#" style={{color:'white',fontSize:'.875rem',textDecoration:'none'}}> <i className="fa fa-phone me-3"></i> 9975757597</a>
                    </ul>
                </div>
                {/* <div className="col-lg-3 col-md-6 mb-4">
                    <h5>More Links</h5>
                    <ul className="link-widget p-0">
                        <li><a href="#">About Us</a></li>
                        <li><a href="#">Our Office</a></li>
                        <li><a href="#">Delivery</a></li>
                        <li><a href="#">Our Store</a></li>
                        <li><a href="#">Guarantee</a></li>
                        <li><a href="#">Buy Gift Card</a></li>
                        <li><a href="#">Return Policy</a></li>
                    </ul>
                </div> */}

                <div className="col-lg-3 col-md-6 mb-4">
                    <h5>Newsletter</h5>
                    <div className="form-group mb-4">
                        <input type="email" className="form-control bg-transparent" placeholder="Enter Your Email Here" />
                        <button type="submit" className="main-btn rounded-2 mt-3 border-white text-white">Contact us</button>
                    </div>
                    <h5>Stay Connected</h5>
                    <ul className="social-network d-flex align-items-center p-0 ">
                        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fab fa-google-plus-g"></i></a></li>
                        <li><a href="#"><i className="fab fa-vimeo-v"></i></a></li>
                    </ul>
                </div>



            </div>
        </div>
        <div className="container-fluid copyright-section">
            <p className="p-0">Copyright <a href="#">©Inphynous Technology Solution Pvt. Ltd.</a> All Rights Reserved</p>
        </div>
    </section>
  )
}

export default Footer