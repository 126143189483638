import React from 'react'
import '../style/Welcome.css'
import Hero from '../img/welcome.png'
const Welcome = () => {
  return (
    <div>
         <section id="home" class="banner_wrapper">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 header-img-section">
                    <img decoding="async" src={Hero} class="img-fluid" alt="Banner" />
                </div>
                <div class="col-md-6 my-5 my-md-0 text-center text-md-start">
                    
                    <h3 class="banner-title"><span style={{color:'var(--secondary-color)'}}>Gurukul Pharmacy College </span><br /> Empowering Future Pharmacists</h3>
                    <p class="banner-title-text">Dnyanyog Shikshan Pharmacy College, established in [year], is a premier institution dedicated to excellence in pharmaceutical education and research. Located in [ Bhokardan], the college offers state-of-the-art facilities, experienced faculty, and a curriculum designed to meet the evolving needs of the healthcare industry.</p>
                    <div class="learn-more-btn-section">
                    <button className="col-4 col-sm-3 align-self-center p-1 mt-2 main-btn"
          
        >Read More</button>
                    </div>
                </div>
            </div>  
        </div>
    </section>
    </div>
  )
}

export default Welcome